*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#root {
  margin: 10px;
}
